 .licence-preview-card {
   /* The image used */
   background-image: url(/images/licence.jpg); 
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
 
   padding: 15px 10px 14px 10px;
   
   table {
     width: 100%; 
     font-weight: bold;
     margin-left: 45px;
     tr {
       th {
         padding: 5px 10px 14px 10px;
         font-size: $defaultFontSize;
       }

       td {
         padding: 2px 8px 2px 8px;
         font-size: $defaultFontSize;
       }
     }
 
   }
 }