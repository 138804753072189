/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-07 10:46:03 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-10 13:52:31
 */


.templateTwoWrapper{
    @extend .fullWidthDiv;

    .templateTwoSideMenu{
        background-color: $menuBGColor;
        width: $menuBarWidth;
        transition:width 1s;    
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: $menuZindex;

        .subMenuHeader{
            height: $appbarHeight;
            width: 100%;
            box-shadow: 0 1px 0 0 rgba(181,189,202,.2),7px 0 15px 0 rgba(69,90,100,.09);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0px 20px;
            
            .logo{                
                width: 120px;
                height: 55px;
                margin-top: 10px;
                margin-left: 35px;
            }

            .iconToggal{    
                align-self: center;
                color: $white;
                font-size: 20px;
                cursor: pointer;        

                &:hover{
                    font-size: 22px;
                }
            }

            .menuIcon{
                animation-name: menuIconKeyframes;
                animation-duration: 1s;
            }

            .closeIcon{
                animation-name: closeIconKeyframes;
                animation-duration: 1s;
            }

            @keyframes menuIconKeyframes {
                from {
                    transform: rotate(-45deg);
                }
                to {                   
                }
            }

            @keyframes closeIconKeyframes {
                from {
                    transform: rotate(-45deg);
                }
                to {
                    transform: rotate(0deg);
                }
            }
        }

        .subMenuWrapper{
            width: 100%;
           // overflow-x: scroll;
        }

        .sideMenuPadding{
            padding: 0px 20px;
            transition: padding 1s;
        }

        .sideMenuLargePadding{
            padding: 0px 25px;
            transition: padding 1s;
        }

        
    }

    .templateTwoSideMenuHide{
      width: $menuBarMinWidth;
      transition:width 1s; 
      .subMenuHeader{
        padding:0px;        

        .iconToggal{    
            visibility: hidden !important;
            transition: visibility .2s; 
        }
        .logo{                
            width: 60px;
            height: 35px;
            margin-top: 10px;
            margin-left: 0px;
        }
      }   
    }

    //body

    .templateTwoBody{
        z-index: $bodyZindex;
        height:  100%;
        margin-left: $menuBarWidth;    
        transition: margin-left 1s;   
        background-color: $bodyColor; 

       
      


        .bodyWrapper{    
           @extend .fullWidthDiv;        
            padding: 15px;
            margin-top: $appbarHeight;           
            
        }
        
    }

    .headerWrapper{
        @extend .fullWidthDiv;
        display: flex;
        flex-direction: row;
        padding: 10px 15px;
        background-color: $white;
        height: $appbarHeight;
        position: fixed;
        z-index: 41;
        box-shadow: 0 1px 0 0 #e2e5e8;

        .breadcrumbWrapper{
           flex:1;   
           flex-direction: column;

           .headerTxtWrapper{

            .pageHeadingTxt{
                font-size: 18px;
                font-weight: 400;
                color: #111;
                margin-bottom: 5px;
            }
           }
           .pageLinks{
                font-size: 13px;
                font-weight: 400;
                font-style: italic;
                .otherLinks{
                    color: $secondaryColor;                     

                    i{
                        font-size: 15px;
                    }

                    .separator{
                        color: $otherLinkSeparator;
                        font-style:normal ;
                        font-size:15px;
                        margin: 0px 5px;
                    }
                }
                .lastLink{
                    color: $otherLinkSeparator;  
                }
           }
        }
        .notificationNaviBar{
            flex:3;      
            display: flex;
            justify-content: flex-end;        
            .notificationUL{
                list-style-type: none;
                margin: 0px;
                margin-top: 10px;
                li{
                    padding: 0px 10px 0px 10px;
                    display: inline;
                    cursor: pointer;
                    .mainIcon{
                        font-size: 20px;
                        
                    }
                    .arraow{
                        font-size: 14px;
                        margin-left: -3px;
                    }
                }
            }
        }

        
    }

    .halfHeader{
        @include calc(width, "100% - #{$menuBarWidth}");
        margin-left: $menuBarWidth !important;
    }

    .fullHeader{
        @include calc(width, "100% - #{$menuBarMinWidth}");
        margin-left: $menuBarMinWidth !important;
    }


   

    .templateTwoBodyExpand{
        margin-left: $menuBarMinWidth !important;
        transition: margin-left 1s;        
        
    }

}