/*
 * @Author: Chanaka Wickramasinghe
 * @Date: 2020-02-20 10:19:32
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-09 15:17:51
 */

// Element Colors
$white: #ffffff;
$black: #000000;

$primaryColor:#4466f2;
$secondaryColor:#2196f3;
$successColor:#22af47;
$infoColor:#007bff;
$warningColor:#ff9f40;
$danger:#ff5370;

$red: #ff0000;
$nobel: #949494;
$geyserColor: #dce1e5;
$darkGrey:#292c37;
$battleshipGreyColor:#6a7583;
$eerieBlack: #212121;
$dodgerBlue:#22B1F7;
$menuLinkLightGray:#6c757d;
$bodyColor:#eff3f6;
$darkBlue_1:#2f3c4e;
$tableBorder:#e9ecef;
$tableRowBGColor:#f8f9fa;

$materialRed: #FF1744;
$materialGreen: #64DD17;

$materialBodyColor:$bodyColor;
$bootstrapBodyColor:$bodyColor;

//app bar
$appBarBGColor:$white;
$appBarIconSeparatorColor:#eff0f1;
$menuBGColor:#242e3e;
$menuFontColor:#b5bdca;
$separatorColor:#e2e5e8;
//
$otherLinkSeparator:#686c71;

/*
* ----------------------------
* Media Queries Screen Sizes and width height
* ----------------------------
*/

//Desktop
$largeDevice: 1200px;
//Tab
$mediumDevice: 992px;
//Mobile
$smallDevice: 768px;
$extraSmallDevice: 576px;
$extraSmallDevice2: 500px;

$mobileBreakPoint:$largeDevice;

//app bar
$appbarHeight:70px;

//menuBar
$menuBarWidth:264px;
$menuBarMinWidth:70px;
/*
* ----------------------------
* Media Queries Screen Sizes width height
* ----------------------------
*/


/*
* ----------------------------
* Padding Values
* ----------------------------
*/

// Common values
$defaultPadding: 15px;
$defaultHalfPadding: $defaultPadding / 2;

/*
* ----------------------------
* Padding Values
* ----------------------------
*/


/*
* ----------------------------
* Margin Values
* ----------------------------
*/

// Common values
$defaultMargin: 15px;
$defaultHalfMargin: $defaultMargin / 2;

/*
* ----------------------------
* Margin Values
* ----------------------------
*/


/*
* ----------------------------
* Element Font Sizes
* ----------------------------
*/

// Common sizes 
$defaultFontSize: 14px; 
$defaultH1: 32px;
$defaultH2: 24px;
$defaultH3: 18px;
$defaultH4: 16px;
$defaultH5: 13px;
$defaultH6: 10px;

// Label sizes
$defaultInputLabel: 15px;
$defaultLabel: 11px;

// Helper text sizes
$defaultHelperText: 11px;

// Button text sizes
$inputBtnTxt: 15px;

$defaultTitle:$defaultH3;
/*
* ----------------------------
* Element Font Sizes
* ----------------------------
*/

//z-index
$menuZindex:50;
$bodyZindex:40;