/*
 * @Author: Chanaka Wickramasinghe
 * @Description: InputButton styles
 * @Date: 2020-02-27 12:26:28
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-29 17:34:45
 */

// InputButton css class

.defaultInputBtnWrapper{
    
    .defaultInputBtnStyle{
        line-height: normal !important;
        padding: 5px 10px 5px 10px !important;
        border-radius: 0px !important;
        min-width: 10px !important;

        .defaultInputBtnTxt{
            font-size: $defaultFontSize;
            text-transform: none !important;
        }

        &:hover{
            background-color: #0c83e2 !important;
        }

        .defaultIconStyle{
            font-size: $defaultFontSize;                        
        }
    }
}



// Material RoundBtn css class
.defaultRoundBtn {

    width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    margin-left: 5px;
    margin-right: 5px;

    .defaultRoundBtnIcon {
        font-size: 11px !important;
        //margin-bottom: 3px;
    }
}

.errorBtn{
    background-color: $danger !important;
}