 .amount-breakedown-card {
  
   table {
     width: 100%;  
     tr { 

       td { 
         padding-top: 5px;
         padding-bottom: 5px;
         font-size: $defaultFontSize;
       }
     }
 
   }
 }