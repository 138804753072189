/**
 * @Author: Sujith
 * @Date: 2020-03-12 10:18:12
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-04-10 11:25:22
 */


 .LoginMainWrapper{
    @include displayFlex(); 
    background-color: $bodyColor;
    width: 100%;
    height: 100%;
    position: fixed;          
    align-items: center;  

    .loginCard{
        margin-left: auto;margin-right: auto;
        padding: 30px !important;
        width: 500px;   
        

        h4{
            text-align: center;
            font-weight: 600;
            letter-spacing: 1px;
            color: #313131;
            font-size: 24px;
            margin-bottom: 8px;
        }
        p{
            color:#777;
            font-size: $defaultFontSize;
            text-align: center;
            margin-bottom: 8px;
        }   
    }
    .resetPasswordCard{
        margin-top: 150px;margin-bottom: auto;
        margin-left: 275px;margin-right: auto;
        padding: 30px !important;
        width: 500px;   
        

        h4{
            text-align: center;
            font-weight: 600;
            letter-spacing: 1px;
            color: #313131;
            font-size: 24px;
            margin-bottom: 8px;
        }
        p{
            color:#777;
            font-size: $defaultFontSize;
            text-align: center;
            margin-bottom: 8px;
        }   
    }
    .loginFormWrapper{
        margin-top: 40px;
    
        .btnWrapper{                     
        }
    }
    .loginHeaderWrapper{
        img{
            float: left;
        }
        h4{
            position: relative;
            top: 10px;
        }
    }

    .passwordWrapper{
        width: 100%;
        position: relative;
        display: flex;
        margin-bottom: 5px;

        .eye{
            position: absolute;
            top: 38%;
            right: 5px;
        }

        .eye:hover{
            color:$dodgerBlue;
            cursor: pointer;
        }

        .help{
            position: absolute;
            top: 38%;
            right: 20px;
        }

        .help:hover{
            color:$dodgerBlue;
            cursor: pointer;
        }

    }
}
.policyItem{
    display: list-item;                                       
    list-style-type: disc;      
    list-style-position: inside;
}

.policyDescription{
    font-size: $defaultH4 !important;
}
// logo styles
.govermentLogo{
    width: 65px;
}
