/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-29 11:54:36 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-29 13:10:16
 */


 .cashierWrapper{
     
    .serialNumberWrapper{

        .defaultInputWrapper{
            @include calc(width, "100% - 24px");
        }

        .defaultInputBtnStyle{
            margin-top: 16px;
            padding: 3px 5px !important;
        } 
        
    }
 }