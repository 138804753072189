 .payment-main-card {

    padding-left: 4px;
    padding-right: 4px;

    .payment-type-card {

   //   height: 40%;
     
   //     .payment-type-row {

   //       padding-bottom: 20px;
   //        //height: 160px;
   //        //border: 1px solid black;

   //     }

    }

 }
