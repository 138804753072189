.report-heading{
    padding-top: 8px;
    padding-bottom: 15px;
    h5{
        color: #111;
        font-size: 16px;
        font-weight: 500;
    }
}

.report-btn-wrapper{
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.report-html-view{
    max-height: 720px;
    overflow: auto;
}

.reportExportToAlignWrapper{
    display: flex;
    align-items: center;
    justify-content: right;
    float: right;

    .exporTypeSelectDropdown{
        min-width: 220px;
        float:right;
        padding-left:16px;
    }

    .exportBtn{
        padding-top: 8px;
        float:right;
        padding-left:16px;
    }
}