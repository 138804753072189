/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 18:17:17 
 * @Last Modified by: Nisal Madusanka(EruliaF)
 * @Last Modified time: 2020-09-15 10:23:32
 */


 .rpWrapper {
    @extend .fullWidthDiv;

    .topWrapper{
        @extend .fullWidthDiv;
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        border-bottom: 1px solid $separatorColor;

        .searchWrapper{
            display: flex;
            flex: 1; 
            flex-direction: row;
            
            .searchInputWrapper{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                width: 350px;
                label{
                    margin-right: 10px;
                    padding-top: 2px;
                    padding-left: 5px;
                }
                input{
                    width: 200px;
                    padding: 0px 5px 0px 5px !important;
                    font-size: $defaultFontSize;
                    max-height: 24px;

                    &:focus{
                        box-shadow: none;
                        border-color: #ced4da;
                    }
                }

                .defaultInputBtnStyle{
                    padding:3px 5px 0px 5px !important;
                    margin-left: 20px;
                    .defaultInputBtnTxt{
                        font-size:16px !important;
                        width: 50px;
                        //color:#2874A6 ;
                    }
                }
            }
            
           
        }
        .statusWrapper{
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .statusUL{
                list-style-type: none;
                margin: 0px;
                li{
                    display: inline; 
                    padding: 0px 15px 0px 15px;
                }
                .setBorder{
                    border-right: 1px solid $separatorColor;
                }
            }
        }
    }

    .issuePermitWrapper{
        @extend .fullWidthDiv;
        padding-top: 10px;
        .documentValidation{
            
            .checkBox_Btn_wrraper{
                margin-top: 10px;
                padding-top:15px;
            }
        }
    }
 }




 //commentHistoryWrapper
 .commentHistoryWrapper{
    @extend .fullWidthDiv;

    .commentElementWrapper{
        @extend .fullWidthDiv;
        border :1px solid $separatorColor;
        background-color: $white;
        padding: 10px;
        margin-bottom: 10px;
        h1{
            margin-bottom: 5px;
            font-size: 14px;
        }
        p{
           background-color: $bodyColor; 
           padding: 10px;
        }
    }
 }

 .chasisNumberLabel{
    margin-top: 20px;
 }

 .redAsterix{
     color: #ff0000;
     font-size: 1.1rem;
     top :0;
 }