/*
 * @Author: Nisal Madusanka(EruliaF) 
 * @Date: 2020-09-08 18:17:17 
 * @Last Modified by: Pradeepa Sandaruwan
 * @Last Modified time: 2021-01-15 14:06:43
 */


 .chassisNohelp{
     cursor: pointer;
     margin-left: 8px;
     font-size: 18px;
 }

 .chasisNoDescription{
    font-size: $defaultH4 !important; 
 }

 .chassisNoHelpImage{
     margin-top: 16px;
     width:90%;
 }